<template>
  <div id="partners_contacts_wrap">
    <div id="partners_contacts" class="pop_up_scroll">
      <div class="opt">
        <div v-for="partner in partners"
             :key="partner.id"
             class="partners_contacts_item"
             :class="{active: partner.id === selectedPartner}"
             @click="selectPartner(partner.id)"
        >
          <div class="partners_title" v-text="partner.title"/>
          <div class="partners_address" v-text="partner.address"/>
          <a v-if="partner.phone" :href="'tel:'+partner.phone.replace(/[^0-9+]/g, '')"
             class="partners_tel">{{ partner.phone }}</a>
          <a v-if="partner.site" :href="~partner.site.indexOf('http') ? partner.site : 'http://'+partner.site"
             target="_blank" class="partners_site">{{ partner.site }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

/**
 * Объект с координатами в формате google maps
 * @typedef {Object} coordinates
 * @property {Number} lat
 * @property {Number} lng
 *
 * Объект с данными о партнере
 * @typedef {Object} partner
 * @property {String} title
 * @property {String} site
 * @property {String} address
 * @property {String} city
 * @property {String} phone
 * @property {String} region
 * @property {coordinates} coordinates
 */

import {mapGetters, mapState} from "vuex";

/**
 * @typedef {Object} PartnersList
 * @property {Array.<partner>} partners
 *
 * @this PartnersList
 */

export default {
  name: "PartnersList",

  computed: {
    ...mapState('PartnersMap', {
      selectedPartner: state => state.filterData.selectedPartner,
    }),

    ...mapGetters('PartnersMap', {
      partners: 'getFilteredPartnersList',
    }),
  },

  methods: {
    selectPartner(partnerId) {
      this.$store.commit('PartnersMap/selectPartner', partnerId);
    }
  }
}
</script>

<style lang="scss">
</style>
