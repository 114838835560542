<template>
  <div class="partner_filter" :class="{open: open}">
    <label class="partner_filter__label" @click="open=true">
      <input class="search_form__input" v-model="inputValue" :placeholder="placeholder">
      <span class="search_form__arrow" @click="open=!open"><i class="far fa-angle-down"></i></span>
    </label>

    <ul class="search_form__list pop_up_scroll default_scroll">
      <li v-for="listElement in filteringList"
          :key="listElement.id" class="search_form__list_item"
          :class="{active: selectedElementId === listElement.id}"
          v-text="listElement.title"
          @click="selectElement(+listElement.id)"
      />
    </ul>
  </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";

    export default {
        name: "FilterSelect",

        data: function () {
            return {
                open: false,
                inputValue: '',
            }
        },

        props: {
            list: {type: Array, default: () => []},
            selectedElementId: {type: Number, default: 0},
            elementType: {type: String, default: () => ''},
            defaultInputValue: {type: String, default: () => ''}
        },

        computed: {
          ...mapGetters('PartnersMap', [
            "filteringList",
          ]),

          /**
           * Функция выборки данных из списка при наборе с клавиатуры
           * @returns {*[]}
           */
            filteringList() {
                if (this.inputValue.length > 3) {
                    let regExp = new RegExp(this.inputValue, 'i');
                    return this.list.filter(elem => regExp.test(elem.title));
                } else {
                    return this.list;
                }
            },

            placeholder: function () {
                if (this.open || this.selectedElementId < 0) {
                    return this.defaultInputValue;
                } else {
                    let selectedElement = this.list.find(elem => +elem.id === this.selectedElementId);
                    if (selectedElement && selectedElement.title) {
                        return selectedElement.title;
                    } else {
                        return this.defaultInputValue;
                    }
                }
            }
        },

        mounted: function(){
          document.addEventListener('click', this.anyClickListener);
        },

        methods: {
            selectElement: function (id) {
              this.$store.commit('PartnersMap/selectElement', {type: this.elementType, id: id});
              this.open = false;
              this.inputValue = '';
            },

            anyClickListener: function (e) {
                e = e || window.event;

                if(e && e.target && !this.$el.contains(e.target)){
                    this.open = false;
                    this.inputValue = '';
                }
            }
        }
    }
</script>

<style></style>
